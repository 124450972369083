<template>
  <div>
    <Pane />
    <a-card class="container">
      <div class="right">
        <EvaluationQrcode v-if="detail.id" :detail="detail" />
        <InterviewQrcode v-if="detail.id" :detail="detail" />
      </div>

      <div class="title">个人信息</div>

      <a-descriptions bordered size="small" :column="3">
        <a-descriptions-item>
          <div slot="label" class="center">证件照</div>
          <div v-if="detail.headerFile">
            <img class="avatar" :src="detail.headerFile.completePath" alt="" />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">姓名</div>
          <div>
            {{ detail.name }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">性别</div>
          <div>
            <DataDictFinder
              dictType="sex"
              :dictValue="detail.sex"
              iconType="text"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">联系方式</div>
          <div>
            {{ detail.mobile }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">邮箱地址</div>
          <div>
            {{ detail.email }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">出生日期</div>
          <div>
            {{ detail.birthday }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">政治面貌</div>
          <div>
            <DataDictFinder
              dictType="politicalOutlook"
              :dictValue="detail.political"
            />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">身高</div>
          <div>{{ detail.height }}cm</div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">体重</div>
          <div>{{ detail.weight }}kg</div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">血型</div>
          <div>
            <DataDictFinder
              dictType="bloodType"
              :dictValue="detail.bloodType"
            />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">婚姻状况</div>
          <div>
            <DataDictFinder
              dictType="marrigeStatus"
              :dictValue="detail.maritalStatus"
            />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">籍贯</div>
          <div>
            {{ detail.nativePlace }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">户口所在地</div>
          <div>
            {{ detail.registered }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">现居住地址</div>
          <div>
            {{ detail.address }}
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <Padding />

      <div class="title">应聘信息</div>

      <a-descriptions bordered size="small" :column="3">
        <a-descriptions-item
          v-if="detail.deliveryChannel !== 'school_enrollment'"
        >
          <div slot="label" class="center">是否离职</div>
          <div>
            <DataDictFinder
              dictType="boolean"
              :dictValue="String(detail.isDeparture)"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">到岗时间</div>
          <div>
            <DataDictFinder
              dictType="workTime"
              :dictValue="detail.arrivalTime"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">应聘部门</div>
          <div>
            {{ detail.deptName }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">
            {{
              detail.deliveryChannel === "school_enrollment"
                ? "投递岗位"
                : "应聘岗位"
            }}
          </div>
          <div>
            {{ detail.post }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item
          v-if="detail.deliveryChannel !== 'school_enrollment'"
        >
          <div slot="label" class="center">期望薪资</div>
          <div>{{ detail.salaryMin }} - {{ detail.salaryMax }}万/年</div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">最高学历</div>
          <div>
            <DataDictFinder dictType="degree" :dictValue="detail.degree" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">是否应届</div>
          <div>
            <DataDictFinder
              dictType="boolean"
              :dictValue="String(detail.isFresh)"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item
          v-if="detail.deliveryChannel === 'school_enrollment'"
        >
          <div slot="label" class="center">是否服从调剂</div>
          <div>
            <DataDictFinder
              dictType="boolean"
              :dictValue="String(detail.isObeyDispensing)"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">兴趣爱好</div>
          <div>
            {{ detail.interests }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">投递时间</div>
          <div>
            {{ detail.deliveryTime }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">简历附件</div>
          <div>
            <a
              v-if="detail.attachmentFile"
              :href="detail.attachmentFile.completePath"
              target="_blank"
              >下载</a
            >
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">获得招聘信息渠道</div>
          <div>
            <DataDictFinder
              dictType="recruit_channel"
              :dictValue="detail.recruitChannel"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item v-if="detail.recruitChannel === 'introduction'">
          <div slot="label" class="center">推荐人</div>
          <div>
            {{ detail.introductionName }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item v-if="detail.recruitChannel === 'channelOther'">
          <div slot="label" class="center">其他</div>
          <div>
            {{ detail.otherContent }}
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <Padding />

      <div class="title">专业信息</div>

      <a-descriptions bordered size="small" :column="3">
        <a-descriptions-item>
          <div slot="label" class="center">专业特长</div>
          <div>
            {{ detail.speciality }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">英语水平</div>
          <div>
            <DataDictFinder
              dictType="englishLevel"
              :dictValue="detail.englishLevel"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item
          v-if="
            detail.englishLevel === 'TOEFL' || detail.englishLevel === 'IELTS'
          "
        >
          <div slot="label" class="center">分数</div>
          <div>
            {{ detail.englishScore }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">计算机水平</div>
          <div>
            <DataDictFinder
              dictType="computerLevel"
              :dictValue="detail.computerLevel"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">职称证书</div>
          <div>
            {{ detail.professionalCert }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">注册证书1</div>
          <div>
            {{ detail.registeredCert1 }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">注册证书2</div>
          <div>
            {{ detail.registeredCert2 }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">注册证书3</div>
          <div>
            {{ detail.registeredCert3 }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">其他证书</div>
          <div>
            {{ detail.registeredCertOther }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">所获奖项1</div>
          <div>
            {{ detail.award1 }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">所获奖项2</div>
          <div>
            {{ detail.award2 }}
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <Padding />

      <div class="title">教育经历</div>

      <a-table bordered :data-source="schoolList" :pagination="false">
        <a-table-column
          title="学校名称"
          data-index="school"
          align="center"
        ></a-table-column>
        <a-table-column title="起止时间" align="center">
          <template slot-scope="text">
            {{ text.timeFrom }}-{{ text.timeTo ? text.timeTo : "至今" }}
          </template>
        </a-table-column>

        <a-table-column title="专业" data-index="major" align="center" />

        <a-table-column title="学习形式" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="education_channel"
              :dictValue="text.channel"
            />
          </template>
        </a-table-column>

        <a-table-column title="学历" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="degree" :dictValue="text.education" />
          </template>
        </a-table-column>
        <a-table-column title="学位" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="academic_degree"
              :dictValue="text.academicDegree"
            />
          </template>
        </a-table-column>
      </a-table>

      <Padding />
      <template v-if="detail.deliveryChannel === 'school_enrollment'">
        <div class="title">校内经历</div>

        <a-table bordered :data-source="hisCampusList" :pagination="false">
          <a-table-column
            title="校内职务"
            data-index="position"
            align="center"
          />
          <a-table-column title="起止时间" align="center">
            <template slot-scope="text">
              {{ text.timeFrom }}-{{ text.timeTo ? text.timeTo : "至今" }}
            </template>
          </a-table-column>

          <a-table-column
            title="负责具体内容"
            data-index="jobContent"
            align="center"
          />
        </a-table>

        <Padding />
      </template>

      <template v-if="detail.deliveryChannel === 'school_enrollment'">
        <div class="title">实习经历</div>

        <a-table bordered :data-source="hisInternshipList" :pagination="false">
          <a-table-column
            title="单位名称"
            data-index="company"
            align="center"
          ></a-table-column>
          <a-table-column title="起止时间" align="center">
            <template slot-scope="text">
              {{ text.timeFrom }}-{{ text.timeTo ? text.timeTo : "至今" }}
            </template>
          </a-table-column>

          <a-table-column
            title="实习内容"
            data-index="internshipContent"
            align="center"
          />
          <a-table-column
            title="实习感悟"
            data-index="internshipPerception"
            align="center"
          />
          <a-table-column
            title="带教老师"
            data-index="leadTeacher"
            align="center"
          />
          <a-table-column
            title="带教老师联系方式"
            data-index="leadTeacherMobile"
            align="center"
          />
        </a-table>

        <Padding />
      </template>

      <div class="title">工作经历</div>

      <a-descriptions
        bordered
        :column="2"
        v-for="item in workList"
        :key="item.id"
        style="margin-bottom: 8px"
      >
        <a-descriptions-item>
          <div slot="label" class="center">单位名称</div>
          <div>
            <span>
              {{ item.company }}
            </span>
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">起止时间</div>
          <div>
            {{ item.timeFrom }}-{{ item.timeTo ? item.timeTo : "至今" }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">职务</div>
          <div>{{ item.position }}</div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">薪资待遇/年薪</div>
          <div>{{ item.salary }}万/年</div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">工作内容</div>
          <div>{{ item.jobContent }}</div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">离职原因</div>
          <div>
            {{ item.leavingReason }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">直接上级</div>
          <div>
            {{ item.superior }} {{ item.superiorPosition }}
            {{ item.superiorMobile }}
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <Padding />

      <div class="title">家庭成员</div>

      <a-table bordered :data-source="familyList" :pagination="false">
        <a-table-column
          title="关系"
          data-index="relation"
          align="center"
        ></a-table-column>
        <a-table-column
          title="姓名"
          data-index="name"
          align="center"
        ></a-table-column>

        <a-table-column title="年龄" data-index="age" align="center" />
        <a-table-column title="工作单位" data-index="company" />
        <a-table-column title="职务" data-index="post" />
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/recruit";
import EvaluationQrcode from "./components//evaluation-qrcode.vue";
import InterviewQrcode from "./components/interview-qrcode.vue";
export default {
  mixins: [watermark],

  components: {
    EvaluationQrcode,
    InterviewQrcode,
  },

  data() {
    return {
      detail: {},
    };
  },

  computed: {
    schoolList() {
      return Array.isArray(this.detail.hisEducationList)
        ? this.detail.hisEducationList
        : [];
    },
    hisCampusList() {
      return Array.isArray(this.detail.hisCampusList)
        ? this.detail.hisCampusList
        : [];
    },
    hisInternshipList() {
      return Array.isArray(this.detail.hisInternshipList)
        ? this.detail.hisInternshipList
        : [];
    },

    workList() {
      return Array.isArray(this.detail.hisWorkList)
        ? this.detail.hisWorkList
        : [];
    },
    familyList() {
      return Array.isArray(this.detail.hisFamilyList)
        ? this.detail.hisFamilyList
        : [];
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    this.getDetail(id);
  },

  methods: {
    getDetail(id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 12px;
}
.title {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #1890ff;
}
.avatar {
  border-radius: 50%;
  height: 64px;
  width: 64px;
  object-fit: fill;
}
</style>
