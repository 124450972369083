<template>
  <a
    class="qrcode"
    v-if="dataURL"
    href="#"
    @click.prevent="download"
    title="点击下载面试者简历二维码"
  >
    <img :src="dataURL" alt="" />
    <div>简历</div>
  </a>
</template>


<script>
import { saveAs } from "file-saver";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      dataURL: "",
    };
  },

  watch: {
    detail() {
      this.getQRCode();
    },
  },

  mounted() {
    this.getQRCode();
  },

  methods: {
    getQRCode() {
      const { id } = this.detail;
      const that = this;
      new window.AwesomeQR.AwesomeQR({
        text: "https://app.njszy.com/resume.html?id=" + id,
        size: 320,
        margin: 0,
        colorDark: "#000000",
      })
        .draw()
        .then((dataURL) => {
          that.dataURL = dataURL;
        });
    },

    download() {
      const { dataURL } = this;
      const { name } = this.detail;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 320;
      canvas.height = 320;

      const image = new Image();
      image.onload = function () {
        context.drawImage(image, 0, 0);

        canvas.toBlob(function (blob) {
          saveAs(blob, `${name}-简历.png`);
        });
      };
      image.src = dataURL;
    },
  },
};
</script>


<style lang="less" scoped>
.qrcode {
  margin-left: 12px;
  text-align: center;
  color: #333;
  img {
    height: 80px;
    width: 80px;
    margin-bottom: 4px;
  }
}
</style>